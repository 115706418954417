<template>
	<div>
		<div style="margin-bottom:10px;margin-top: 10px">
			<div style="margin-bottom:10px;float: left;">
				<el-date-picker v-model="listQuery.period" type="month" format="YYYYMM" value-format="YYYYMM" placeholder="请选择账期" size="mini" style="margin-left: 10px;width: 120px;" @change="getList()" :clearable="false"> </el-date-picker>
				<selectAddress v-model:address="listQuery.address" @success="getList()" style="margin-left:10px"></selectAddress>
			</div>
			<div style="float:right;margin-right: 10px;">
        <el-button type="primary" @click="openDialogAddress">按地区统计</el-button>
			</div>
		</div>
		<el-table size="mini" v-loading="isLoading" :data="list"  border style="width: 100%" :height="contentStyleObj" :header-cell-style="{background:'#66b1ff',color:'#ffff'}" @selection-change="handleSelectionChange" show-summary>
      <el-table-column fixed type="index" width="55" label="序号" align="center"  ></el-table-column>
      <el-table-column fixed label="公司名" align="center" min-width="250">
        <template #default="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>

			<el-table-column label="开通账套" width="90" align="center" prop="total">
       <template #default="scope">
        {{scope.row.total}}
       </template>
      </el-table-column>
			<el-table-column label="已用账套" width="90" align="center" prop="count">
       <template #default="scope">
        {{scope.row.count}}
       </template>
      </el-table-column>
			<el-table-column label="本期记账数" align="center" min-width="190" prop="periodCount">
       <template #default="scope">
        {{scope.row.periodCount}}
       </template>
      </el-table-column>
			<el-table-column label="本期申报数" align="center" min-width="190" prop="sum">
       <template #default="scope">
        {{scope.row.sum}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功数" align="center" min-width="190" prop="ok">
       <template #default="scope">
        {{scope.row.ok}}
       </template>
      </el-table-column>
			<el-table-column label="申报失败数" align="center" min-width="190" prop="no">
       <template #default="scope">
        {{scope.row.no}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功率" align="center" min-width="190">
       <template #default="scope">
				<span v-if="isNaN(scope.row.ok/scope.row.sum)"></span>
				<span v-else>{{(parseInt((scope.row.ok/scope.row.sum)*100) +'%')}}</span>
       </template>
      </el-table-column>
			<el-table-column label="本期申报数（个税）" align="center" min-width="190" prop="gsSum">
       <template #default="scope">
        {{scope.row.gsSum}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功数（个税）" align="center" min-width="190" prop="gsOk">
       <template #default="scope">
        {{scope.row.gsOk}}
       </template>
      </el-table-column>
			<el-table-column label="申报失败数（个税）" align="center" min-width="190" prop="gsNo">
       <template #default="scope">
        {{scope.row.gsNo}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功率（个税）" align="center" min-width="190" >
       <template #default="scope">
				<span v-if="isNaN(scope.row.gsOk/scope.row.gsSum)"></span>
				<span v-else>{{(parseInt((scope.row.gsOk/scope.row.gsSum)*100)+'%')}}</span>
        
       </template>
      </el-table-column>
      
    </el-table>

		<div class="pagination">
			<qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
				@pagination="getList" />
		</div>

		<el-dialog v-model="dialogTableVisible" title="按地区统计" width="80%">
			<el-table size="mini" v-loading="isAddressLoading" :data="addresssList"  border style="width: 100%" :height="contentStyleObj1" :header-cell-style="{background:'#66b1ff',color:'#ffff'}" @selection-change="handleSelectionChange" show-summary>
      <el-table-column fixed type="index" width="60" label="序号" align="center"  ></el-table-column>
      <el-table-column fixed label="地区名" align="center" min-width="100">
        <template #default="scope">
					{{ $cityFilter(scope.row.name)}}
        </template>
      </el-table-column>

			<el-table-column label="开通账套" align="center" prop="total">
       <template #default="scope">
        {{scope.row.total}}
       </template>
      </el-table-column>
			<el-table-column label="已用账套" align="center" prop="count">
       <template #default="scope">
        {{scope.row.count}}
       </template>
      </el-table-column>
			<el-table-column label="本期记账数" align="center" min-width="100" prop="periodCount">
       <template #default="scope">
        {{scope.row.periodCount}}
       </template>
      </el-table-column>
			<el-table-column label="本期申报数" align="center" min-width="100" prop="sum">
       <template #default="scope">
        {{scope.row.sum}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功数" align="center" min-width="100" prop="ok">
       <template #default="scope">
        {{scope.row.ok}}
       </template>
      </el-table-column>
			<el-table-column label="申报失败数" align="center" min-width="100" prop="no">
       <template #default="scope">
        {{scope.row.no}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功率" align="center" min-width="100">
       <template #default="scope">
				<span v-if="isNaN(scope.row.ok/scope.row.sum)"></span>
				<span v-else>{{(parseInt((scope.row.ok/scope.row.sum)*100) +'%')}}</span>
       </template>
      </el-table-column>
			<el-table-column label="本期申报数（个税）" align="center" min-width="100" prop="gsSum">
       <template #default="scope">
        {{scope.row.gsSum}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功数（个税）" align="center" min-width="100" prop="gsOk">
       <template #default="scope">
        {{scope.row.gsOk}}
       </template>
      </el-table-column>
			<el-table-column label="申报失败数（个税）" align="center" min-width="100" prop="gsNo">
       <template #default="scope">
        {{scope.row.gsNo}}
       </template>
      </el-table-column>
			<el-table-column label="申报成功率（个税）" align="center" min-width="100">
       <template #default="scope">
				<span v-if="isNaN(scope.row.gsOk/scope.row.gsSum)"></span>
				<span v-else>{{(parseInt((scope.row.gsOk/scope.row.gsSum)*100)+'%')}}</span>
        
       </template>
      </el-table-column>
      
    </el-table>
		</el-dialog>
	</div>
</template>

<script>
import selectAddress from '@/components/select/selectAddress';
import {  getStatistics ,getStatisticsAddress } from '@/api/taxBureau'
export default {
	name:'taskStatistics',
	components: {
		selectAddress
	},
	data() {
		return {
			list:[],
			listQuery:{
				address:'',
				period:this.$currentAccountPeriod(),
				limit:50,
				page:1
			},
			listQuery1:{
				period:this.$currentAccountPeriod(),
			},
			isLoading:false,
			isAddressLoading:false,
			total: 0,
			dialogTableVisible:false,
			addresssList:[]
		}
	},
	created() {
		this.getList()
		this.contentStyleObj = this.$getHeight(280)
		this.contentStyleObj1 = this.$getHeight(380)

	},
	methods: {
    getList(){
			this.isLoading = true
			getStatistics(this.listQuery).then(res => {
				this.isLoading = false
				if(res.data.msg == 'success'){
					this.list = res.data.data.list
					this.total = res.data.data.total
				}
			})
		},
		openDialogAddress(){
			this.isAddressLoading = true
			getStatisticsAddress(this.listQuery1).then(res => {
				this.isAddressLoading = false
				if(res.data.msg == 'success'){
					this.addresssList = res.data.data.list
				}
			})
      this.dialogTableVisible = true
		}
	},
	activated() {

	},
}
</script>
<style lang="scss">
.shubiao {
	cursor: pointer;
}
</style>
<style lang="scss" scoped>
.el-table {
	   th {
		padding: 0px;
	}

	   td {
		padding: 0px;
	}
}

.home {
	padding: 10px;

	.header {
		margin-bottom: 10px;

		.right {
			float: right;
		}

		.input-search {
			width: 180px;
			margin-right: 5px;
		}
	}

	.img-ewm {
		width: 50px;
	}
}

.pagination {
	text-align: right;
	margin-right: 26px;
	margin-top: 16px;
}

.el-input {
	width: 80%;
}

.item_icon {
	display: inline-block;
	cursor: pointer;

	i {
		display: inline-block;
		line-height: 28px;
		font-size: 16px;
	}

	.el-icon-error {
		color: #f56c6c;
	}

	.el-icon-success {
		color: #67c23a;
	}

	.el-icon-circle-check {
		color: #67c23a;
	}

	.el-icon-info {
		color: #409eff
	}

	.el-icon-warning {
		color: #e6a23c;
	}

	.el-icon-warning-outline {
		color: #e6a23c;
	}

	p {
		display: inline-block;
		font-size: 14px;
		line-height: 28px;
		color: #333;
	}
}

</style>
<style>
.el-message-box {
	width: 60% !important;
}
.pagination {
	text-align: right;
	margin-right: 26px;
	margin-top: 16px;
}
</style>